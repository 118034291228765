import router from '@user-plugins/routes/index.js'
import axiosInstanceUser from '@user-plugins/axios/index.js'

export default {
    namespaced: true,
    state: {
        authenticated: false,
        access_token: '',
        user: {},
        twofactor: true,
        form: {
            username: '',
            password: '',
            remember: false
        },
        verify: {
            code: ''
        },
        processing: false,
        error: {
            status: false,
            message: null
        },
        resetpassword: {
            email: ''
        },
        changepassword: {
            email: '',
            token: '',
            new_password: '',
            confirm_the_new_password: ''
        }
    },
    mutations: {
        set_authenticated(state, value) {
            state.authenticated = value
        },
        set_user(state, user) {
            state.user = user
        },
        set_access_token(state, value) {
            state.access_token = value
        },
        set_twofactor(state, value) {
            state.twofactor = value
        },
        set_logout(state) {
            state.authenticated = false
            state.user = {}
            state.access_token = ''
            router.push({name: 'login'}).then(() => {
            })
        },
        set_error(state, error) {
            state.error = error
        },
        set_email_token(state, obj) {
            state.changepassword.email = obj.email
            state.changepassword.token = obj.token
        }
    },
    actions: {
        async login({state, commit}) {
            state.processing = true
            await axiosInstanceUser.post('/login', state.form).then(({data}) => {
                commit('set_user', data.user)
                commit('set_twofactor', true);
                router.push({name: 'verify-2fa'})
            }).catch(({response}) => {
                commit('set_error', {status: true, message: response.data.message})
            }).finally(() => {
                state.processing = false
            })
        },

        async verify({state, commit}) {
            state.processing = true
            await axiosInstanceUser.post('/verify', state.verify).then(({data}) => {
                commit('set_authenticated', true)
                commit('set_user', data.user)
                commit('set_access_token', data.access_token)
                router.push({name: 'dashboard'})
            }).catch(({response}) => {
                commit('set_error', {status: true, message: response.data.message})
            }).finally(() => {
                state.processing = false
            })
        },

        async resend({state, commit}) {
            state.processing = true
            await axiosInstanceUser.get('/resend').then(({data}) => {
            }).catch(({response}) => {
                commit('set_error', {status: true, message: response.data.message})
            }).finally(() => {
                state.processing = false
            })
        },

        async send_link_reset({state, commit}) {
            state.processing = true
            await axiosInstanceUser.post('/forgot-password', state.resetpassword).then(({data}) => {
                console.log(data)
            }).catch(({response}) => {
                commit('set_error', {status: true, message: response.data.message})
            }).finally(() => {
                state.processing = false
            })
        },

        async change_password({state, commit}) {
            state.processing = true
            await axiosInstanceUser.post('/change-password', state.changepassword).then(() => {
                router.push({name: 'login'})
            }).catch(({response}) => {
                commit('set_error', {status: true, message: response.data.message})
            }).finally(() => {
                state.processing = false
            })
        },

        async logout({state, commit}) {
            await axiosInstanceUser.post('/logout', state.user, {
                headers: {
                    Authorization: state.access_token,
                }
            }).then(() => {
                commit('set_authenticated', false)
                commit('set_user', {})
                commit('set_access_token', null)
                router.push({name: 'login'}).then(() => {
                })
            }).catch(() => {
            }).finally(() => {})

        }
    }
}
