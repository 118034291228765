import axiosInstanceUser from '@user-plugins/axios/index.js'

export default  {
    namespaced: true,
    state: {
        payslip: {},
        loading: true,
        search: '',
        total_payslips: 0,
        per_page: 10,
        current_page: 1,
        total_files: 0
    },
    mutations: {
        set_payslips(state, payslips) {
            state.payslips = payslips
        },
        set_total_payslips(state, total_payslips) {
            state.total_payslips = total_payslips
        },
        set_current_page(state, current_page) {
            state.current_page = current_page
        },
        set_per_page(state, value) {
            state.per_page = value
        },
        set_search(state, value) {
            state.search = value
        },
    },
    actions: {
        async get({rootState, state, commit}, page) {
            state.loading = true
            await axiosInstanceUser.get(`/payslips/get`, {
                params: {
                    page,
                    per_page: encodeURIComponent(state.per_page),
                },
                headers: {
                    Authorization: rootState.auth.access_token,
                },
            }).then((response) => {
                commit('set_payslips', response.data.data)
                commit('set_total_payslips', response.data.total)
                commit('set_current_page', response.data.current_page)
            }).catch(() => {
            }).finally(() => {
                state.loading = false
            })
        },
        async download({ rootState, state, commit }, file) {
            await axiosInstanceUser.get(`/payslips/download/${file.id}`, {
                headers: {
                    Authorization: rootState.auth.access_token,
                },
                responseType: 'blob'
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', file.name);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
            }).catch(() => {}).finally(() => {})
        },

    }
}
