import axiosInstanceUser from '@user-plugins/axios/index.js'

export default {
    namespaced: true,
    state: {
        chart_data: {
            labels: [
                '01',
                '02',
                '03',
                '04',
                '05',
                '06',
                '07',
                '08',
                '09',
                '10',
                '11',
                '12'
            ],
            datasets: [
                {
                    label: 'Chargements',
                    backgroundColor: '#ff4c51',
                    data: []
                },
                {
                    label: 'Téléchargements',
                    backgroundColor: '#28c76f',
                    data: []
                }
            ]
        },
        chart_options: {
            responsive: true,
            maintainAspectRatio: false
        },
        loading: false,
        counts: {
            users_active: 0,
            admin_active: 0,
            payslips: 0,
            stc: 0,
            downloads: 0
        },
        logs: [],
        logs_loading: false,
        logs_page: 1,
    },
    mutations: {
        set_loading(state, loading) {
            state.loading = loading
        },
        set_counts(state, counts) {
            state.counts.users_active = counts['users_active']
            state.counts.admin_active = counts['admin_active']
            state.counts.payslips = counts['payslips']
            state.counts.stc = counts['stc']
            state.counts.downloads = counts['downloads']
        },
        set_logs_loading(state, logs_loading) {
            state.logs_loading = logs_loading
        },
        set_logs(state, logs) {
            state.logs.push(...logs)
        },
        set_logs_page(state) {
            state.logs_page = state.logs_page + 1
        },
    },
    actions: {

        async get({rootState, state, commit}) {
            commit('set_loading', true)
            await axiosInstanceUser.get(`/dashboard`, {
                headers: {
                    Authorization: rootState.auth.access_token,
                },
            }).then((response) => {
                /*state.chart_data.labels.map(element => {
                    const data = response.data.statistics[element] || [];
                    state.chart_data.datasets[0].data.push(data.length);
                    state.chart_data.datasets[1].data.push(data.map(({downloads}) => downloads).reduce((a, b) => a + b, 0));
                });*/
                commit('set_counts', response.data.counts)
            }).catch(() => {
            }).finally(() => {
                commit('set_loading', false)
            })
        },

        async logs({rootState, state, commit}) {
            commit('set_logs_loading', true)
            await axiosInstanceUser.get(`/logs`, {
                params: {
                    page: state.logs_page
                },
                headers: {
                    Authorization: rootState.auth.access_token,
                },
            }).then(({data}) => {
                commit('set_logs', data.data)
                commit('set_logs_page')
            }).catch(() => {
            }).finally(() => {
                commit('set_logs_loading', false)
            })
        }

    }
}
